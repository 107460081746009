<template>
  <div class="intro-y flex items-center mt-8">
    <h2 class="text-lg font-medium mr-auto">SVG Loaders</h2>
  </div>
  <!-- BEGIN: Icon List -->
  <div class="intro-y grid grid-cols-12 sm:gap-6 row-gap-6 box px-5 py-8 mt-5">
    <div
      class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
    >
      <LoadingIcon icon="audio" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">audio</div>
    </div>
    <div
      class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
    >
      <LoadingIcon icon="ball-triangle" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">ball-triangle</div>
    </div>
    <div
      class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
    >
      <LoadingIcon icon="bars" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">bars</div>
    </div>
    <div
      class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
    >
      <LoadingIcon icon="circles" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">circles</div>
    </div>
    <div
      class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
    >
      <LoadingIcon icon="grid" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">grid</div>
    </div>
    <div
      class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
    >
      <LoadingIcon icon="hearts" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">hearts</div>
    </div>
    <div
      class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
    >
      <LoadingIcon icon="oval" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">oval</div>
    </div>
    <div
      class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
    >
      <LoadingIcon icon="puff" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">puff</div>
    </div>
    <div
      class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
    >
      <LoadingIcon icon="rings" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">rings</div>
    </div>
    <div
      class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
    >
      <LoadingIcon icon="spinning-circles" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">spinning-circles</div>
    </div>
    <div
      class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
    >
      <LoadingIcon icon="tail-spin" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">tail-spin</div>
    </div>
    <div
      class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
    >
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">three-dots</div>
    </div>
  </div>
  <!-- END: Icon List -->
</template>
